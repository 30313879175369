.footer-container {
  background-color: #14213d;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.footer-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.footer-logo-img {
  width: 60px;
  height: auto;
  margin-bottom: 10px;
}

.footer-company-name {
  font-size: 1.5rem;
}

.footer-social-links {
  display: flex;
  gap: 15px;
  font-size: 1.5rem;
}

.social-link {
  color: #fff;
  transition: color 0.3s;
}

.social-link:hover {
  color: #00acee; /* Adjust hover color */
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}

.footer-column {
  text-align: left;
}

.footer-column h4 {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #aaa;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: #fff;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.9rem;
}
