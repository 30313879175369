* {
  box-sizing: border-box;
  scroll-behavior: smooth
}

.wrapper {
  margin: auto;
  max-width: 1000px;
}

