$pad-width: 768px;

$suez-font: 'SuezOne Regular';
$gilda-font: 'Gilda Regular';
$palanquin-font: 'Palanquin Regular';

@font-face {
  font-family: 'SuezOne Regular';
  src: url('./fonts/SuezOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilda Regular';
  src: url('./fonts/GildaDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Palanquin Regular';
  src: url('./fonts/GildaDisplay-Regular.ttf') format('truetype');
}
